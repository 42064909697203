import React from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Grid, { GridItem } from 'components/Grid'
import SEO from '../components/seo'
import Button from '../components/Button'
import Layout from '../components/Layout'

import styles from './donate.module.scss'

const DonatePage = ({ data }) => {
  // eslint-disable-next-line react/prop-types
  const Laptop = data.Laptop.childImageSharp.fluid

  return (
    <Layout className={styles.page}>
      <SEO title="Donate" />
      <section className={cx(styles.root, "pgHead")}>
        <div
          className={cx(styles.root, "content")}
          style={{ padding: "0 2em" }}
        >
          <h1 className={cx(styles.title, "boldColor content-max-width")}>
            Together, we can distribute 1 Million meals in 2021!
          </h1>
        </div>
      </section>
      <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611851858/seniorholdingmeal_rgdwbs_bbatqk.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Meals on Wheels Program"
        />
      <section className={cx(styles.imageGrid, "mt--xl, mb--xl")}>
      {/* <section className={cx(styles.root, "content")}> */}

        {/* <Img
          fluid={Laptop}
          alt="Donate laptops"
          className={cx(styles.logo)}
          align="right"
        /> */}
      </section>
      <div className={cx(styles.root, "content")}>
        <Grid gap={4}>
          <Grid col={2} gap={4}>
            <GridItem justify="left">
              {/* <img
                className={cx(styles.logo)}
                src="https://res.cloudinary.com/afrodeli/image/upload/v1611862469/Yellow_Black_1_Million_Milestone_Instagram_Square_rvgv9g.png"
                alt="Feeding Community Million Meals Fundraiser"
              /> */}
              {/* <p>
                <b>We're fundraising to distribute 1 Million Meals in 2021!</b>
              </p> */}
              <p>
                Last year, over a period of 8 months, we were able to distribute
                over 500,000 meals to community members in need. We were only
                able to do so with the support of our amazing community of
                supporters and organizational partners. With the public health
                situation continuing, we are preparing to support even more
                community members in 2021.{" "}
              </p>
              <p>
                That’s the goal! And we need your help to do it! Every donation
                makes a real difference, no matter the size. Every dollar
                counts, and will help feed our neighbors, friends, and families
                in need.</p>
                <p>
                <b>Join us on the path to 1M!</b>
              </p>

              {/* <iframe
                title="Donate to Emergent Works!"
                allowpaymentrequest=""
                className={styles.donorbox}
                frameBorder="0"
                height="900px"
                name="donorbox"
                scrolling="no"
                seamless="seamless"
                src="https://donorbox.org/embed/website-donation-15"
                width="100%"
              ></iframe> */}
            </GridItem>
            <GridItem align="left" className="gbIframe">
              <h2 className="mt--none">Support our work</h2>
              <p>
                <strong>$50</strong> will pay for 8-10 meals.
              </p>
              <p>
                <strong>$100</strong> will pay for 20 meals.
              </p>
              <p>
                <strong>$250</strong> will pay for over 50 meals.
              </p>
              <p>
                <strong>$500</strong> will pay for over 100 meals.
              </p>

              <p>
                {" "}
                <i>
                  As a volunteer-run initiative, less than 5% of funds are spent
                  on administrative costs.
                </i>
              </p>
            </GridItem>
          </Grid>
        </Grid>
      </div>

      <div className={cx(styles.root, "mt--xxl mb--lg content")}>
        <iframe
          title="Donate to Feeding Community Initiative"
          allowpaymentrequest=""
          className={styles.butter}
          src="https://givebutter.com/embed/c/1MillionMeals"
          name="givebutter"
          frameborder="0"
          scrolling="no"
          seamless="seamless"
          width="100%"
        ></iframe>
        {/* <iframe
          title="Donate to Emergent Works!"
          allowpaymentrequest=""
          className={styles.donorbox}
          frameBorder="0"
          height="900px"
          name="donorbox"
          src="https://donorbox.org/embed/website-donation-15"
          width="100%"
        ></iframe> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    Laptop: file(relativePath: { eq: "fc/millionmeals.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default DonatePage
